var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "row" },
    [
      _c(
        "div",
        { staticClass: "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" },
        [
          _c(
            "c-table",
            {
              ref: "grid",
              attrs: {
                title: "공종",
                tableId: "grid",
                columns: _vm.grid.columns,
                data: _vm.grid.data,
                selection: "multiple",
                rowKey: "processCd",
                editable: _vm.editable,
                plantCd: _vm.param.plantCd,
                isFullScreen: false,
                columnSetting: false,
                filtering: false,
                usePaging: false,
                gridHeight: "400px",
              },
            },
            [
              _c(
                "template",
                { slot: "table-button" },
                [
                  _c(
                    "q-btn-group",
                    { attrs: { outline: "" } },
                    [
                      _vm.editable
                        ? _c("c-btn", {
                            attrs: {
                              label: "타 공사현장정보에서 선택",
                              icon: "add",
                            },
                            on: { btnClicked: _vm.addOtherProcess },
                          })
                        : _vm._e(),
                      _vm.editable
                        ? _c("c-btn", {
                            attrs: { label: "직접추가", icon: "add" },
                            on: { btnClicked: _vm.addrow3 },
                          })
                        : _vm._e(),
                      _vm.editable
                        ? _c("c-btn", {
                            attrs: { label: "선택", icon: "add" },
                            on: { btnClicked: _vm.addrow },
                          })
                        : _vm._e(),
                      _vm.editable
                        ? _c("c-btn", {
                            attrs: { label: "저장", icon: "save" },
                            on: { btnClicked: _vm.saveMat },
                          })
                        : _vm._e(),
                      _vm.editable
                        ? _c("c-btn", {
                            attrs: { label: "삭제", icon: "remove" },
                            on: { btnClicked: _vm.deleteMat },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            2
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" },
        [
          _c(
            "c-table",
            {
              ref: "grid2",
              attrs: {
                title: "도급업체 정보",
                tableId: "grid2",
                columns: _vm.grid2.columns,
                data: _vm.grid2.data,
                selection: "multiple",
                rowKey: "pjmProjectVendorId",
                isFullScreen: false,
                editable: _vm.editable,
                columnSetting: false,
                filtering: false,
                usePaging: false,
                gridHeight: "350px",
                plantCd: _vm.param.plantCd,
              },
              scopedSlots: _vm._u([
                {
                  key: "customArea",
                  fn: function ({ props, col }) {
                    return [
                      col.name === "processNames"
                        ? [
                            _c("c-process", {
                              attrs: {
                                editable: _vm.editable,
                                multiple: "multiple",
                                label: "",
                                name: "processCds",
                              },
                              on: {
                                datachange: function ($event) {
                                  return _vm.datachange(props.row)
                                },
                              },
                              model: {
                                value: props.row.processCds,
                                callback: function ($$v) {
                                  _vm.$set(props.row, "processCds", $$v)
                                },
                                expression: "props.row.processCds",
                              },
                            }),
                          ]
                        : _vm._e(),
                    ]
                  },
                },
              ]),
            },
            [
              _c(
                "template",
                { slot: "table-button" },
                [
                  _c(
                    "q-btn-group",
                    { attrs: { outline: "" } },
                    [
                      _vm.editable
                        ? _c("c-btn", {
                            attrs: { label: "추가", icon: "add" },
                            on: { btnClicked: _vm.addrow2 },
                          })
                        : _vm._e(),
                      _vm.editable
                        ? _c("c-btn", {
                            attrs: { label: "저장", icon: "save" },
                            on: { btnClicked: _vm.saveMat2 },
                          })
                        : _vm._e(),
                      _vm.editable
                        ? _c("c-btn", {
                            attrs: { label: "삭제", icon: "remove" },
                            on: { btnClicked: _vm.deleteMat2 },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            2
          ),
        ],
        1
      ),
      _c("c-dialog", { attrs: { param: _vm.popupOptions } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }