<template>
  <div class="row">
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
      <c-table
        ref="grid"
        title="공종"
        tableId="grid"
        :columns="grid.columns"
        :data="grid.data"
        selection="multiple"
        rowKey="processCd"
        :editable="editable"
        :plantCd="param.plantCd"
        :isFullScreen="false"
        :columnSetting="false"
        :filtering="false"
        :usePaging="false"
        gridHeight="400px"
      >
        <template slot="table-button">
          <q-btn-group outline >
            <c-btn v-if="editable" label="타 공사현장정보에서 선택" icon="add" @btnClicked="addOtherProcess" />
            <c-btn v-if="editable" label="직접추가" icon="add" @btnClicked="addrow3" />
            <c-btn v-if="editable" label="선택" icon="add" @btnClicked="addrow" />
            <c-btn v-if="editable" label="저장" icon="save" @btnClicked="saveMat" />
            <c-btn v-if="editable" label="삭제" icon="remove" @btnClicked="deleteMat" />
          </q-btn-group>
        </template>
      </c-table>
    </div>
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
      <c-table
        ref="grid2"
        title="도급업체 정보"
        tableId="grid2"
        :columns="grid2.columns"
        :data="grid2.data"
        selection="multiple"
        rowKey="pjmProjectVendorId"
        :isFullScreen="false"
        :editable="editable"
        :columnSetting="false"
        :filtering="false"
        :usePaging="false"
        gridHeight="350px"
        :plantCd="param.plantCd"
      >
        <!-- 버튼 영역 -->
        <template slot="table-button">
          <q-btn-group outline >
            <c-btn v-if="editable" label="추가" icon="add" @btnClicked="addrow2" />
            <c-btn v-if="editable" label="저장" icon="save" @btnClicked="saveMat2" />
            <c-btn v-if="editable" label="삭제" icon="remove" @btnClicked="deleteMat2" />
          </q-btn-group>
        </template>
        <template v-slot:customArea="{ props, col }">
          <template v-if="col.name === 'processNames'">
            <c-process
              :editable="editable"
              multiple="multiple"
              label=""
              name="processCds"
              v-model="props.row.processCds"
              @datachange="datachange(props.row)">
            </c-process>
          </template>
        </template>
      </c-table>
    </div>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>
<script>
import { uid } from 'quasar';
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'project-process',
  props: {
    param: {
      type: Object,
      default: () => ({
        plantCd: '',
        updateFlag: '',
      }),
    },
  },
  data() {
    return {
      selectedSop: {},
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        width: '50%',
        param: {},
        closeCallback: null,
      },
      grid: {
        columns: [
          {
            name: 'constructTypeName',
            field: 'constructTypeName',
            label: '공사형태',
            align: 'center',
            style: "width:200px",
            type: 'text',
            sortable: false,
          },
          {
            name: 'processName',
            field: 'processName',
            label: '공종',
            align: 'center',
            style: "width:300px",
            type: 'text',
            sortable: false,
          },
          {
            required: true,
            name: 'planStartDt',
            field: 'planStartDt',
            label: '시작예정일',
            align: 'center',
            style: "width:150px",
            type: 'date',
            sortable: false,
          },
          {
            required: true,
            name: 'planEndDt',
            field: 'planEndDt',
            label: '종료예정일',
            align: 'center',
            style: "width:150px",
            type: 'date',
            sortable: false,
          },
          {
            name: 'realStartDt',
            field: 'realStartDt',
            label: '실제시작일',
            align: 'center',
            style: "width:150px",
            type: 'date',
            sortable: false,
          },
          {
            name: 'realEndDt',
            field: 'realEndDt',
            label: '실제종료일',
            align: 'center',
            style: "width:150px",
            type: 'date',
            sortable: false,
          },
          {
            name: 'resultPercent',
            field: 'resultPercent',
            label: '진행율(%)',
            align: 'right',
            style: "width:100px",
            type: 'number',
            sortable: false,
          },
          // {
          //   required: true,
          //   name: 'vendors',
          //   field: 'vendors',
          //   label: '공사업체(복수)',
          //   align: 'center',
          //   style: 'width:300px',
          //   type: 'vendorMulti',
          //   sortable: false
          // },
          {
            name: 'processDesc',
            field: 'processDesc',
            label: '비고',
            align: 'left',
            type: 'text',
            sortable: false,
          },
        ],
        data: [],
      },
      grid2: {
        columns: [
          {
            name: 'vendorName',
            field: 'vendorName',
            label: '업체명',
            required: true,
            description: 'upVendorName',
            align: 'center',
            style: "width:200px",
            sortable: false,
          },
          {
            name: 'processNames',
            field: 'processNames',
            label: '관련공종',
            align: 'left',
            type: 'custom',
            style: "width:200px",
            sortable: false,
          },
          {
            name: 'contractDt',
            field: 'contractDt',
            label: '계약일',
            align: 'center',
            style: "width:120px",
            type: 'date',
            sortable: false,
          },
          {
            name: 'constructionStartDt',
            field: 'constructionStartDt',
            label: '공사시작일',
            align: 'center',
            style: "width:120px",
            type: 'date',
            sortable: false,
          },
          {
            name: 'constructionEndDt',
            field: 'constructionEndDt',
            label: '공사종료일',
            align: 'center',
            style: "width:120px",
            type: 'date',
            sortable: false,
          },
        ],
        data: [],
      },
      selectedProcess: {},
      editable: true,
      list1Url: '',
      save1Url: '',
      delete1Url: '',
      list2Url: '',
      save2Url: '',
      delete2Url: '',
      processItems: [],
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
  },
  methods: {
    init() {
      // role setting
      if(this.$store.getters.user.innerFlag === 'N') {
        this.editable = false;
      } else {
        this.editable = this.$route.meta.editable;
      }
      // url setting
      this.list1Url = selectConfig.pjm.process.list.url;
      this.save1Url = transactionConfig.pjm.process.save.url;
      this.delete1Url = transactionConfig.pjm.process.delete.url;
      this.list2Url = selectConfig.pjm.vendor.list.url;
      this.save2Url = transactionConfig.pjm.vendor.save.url;
      this.delete2Url = transactionConfig.pjm.vendor.delete.url;
      
      this.getList();
      this.getList2();
    },
    getList() {
      if (this.param.plantCd) {
        this.$http.url = this.$format(this.list1Url, this.param.plantCd);
        this.$http.type = 'GET';
        this.$http.request((_result) => {
          this.grid.data = _result.data;
        },);
      }
    },
    getList2() {
      if (this.param.plantCd) {
        this.$http.url = this.list2Url
        this.$http.type = 'GET';
        this.$http.param = {
          plantCd: this.param.plantCd
        };
        this.$http.request((_result) => {
          this.grid2.data = _result.data;
        },);
      }
    },
    addrow() {
      this.popupOptions.title = '공종마스터 검색';
      this.popupOptions.param = {
        type: 'multiple',
        plantCd: this.param.plantCd
      };
      this.popupOptions.target = () => import(`${"@/pages/mdm/pim/processMstPop.vue"}`);
      this.popupOptions.visible = true;
      this.popupOptions.isFull = false;
      this.popupOptions.width = '60%';
      this.popupOptions.closeCallback = this.closeProcessPopup;
    },
    closeProcessPopup(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (data && data.length > 0) {
        this.$_.forEach(data, _item => {
          if(this.$_.findIndex(this.grid.data, { processCd: _item.processCd }) === -1) {
            this.grid.data.push({
              processCd: _item.processCd,
              processName: _item.processName,
              constructTypeCd: _item.constructTypeCd,
              constructTypeName: _item.constructTypeName,
              editFlag: 'C',
              useFlag: 'Y',
              plantCd: this.param.plantCd,  // 사업장코드
              processLevelCd: '20',  // 코드(공사구분:10, 공종 : 20)
              planStartDt: '',  // 시작예정일
              planEndDt: '',  // 종료예정일
              realStartDt: '',  // 실제시작일
              realEndDt: '',  // 실제종료일
              resultPercent: '',  // 실적진행율
              vendors: '', // 공사업체
              remark: '',  // 비고
              sortOrder: '',  // 정렬순서
            })
          }
        })
      }
    },
    addrow3() {
      this.grid.data.push({
        processCd: uid(),
        processName: '',
        constructTypeCd: '',
        constructTypeName: '',
        editFlag: 'C',
        useFlag: 'Y',
        plantCd: this.param.plantCd,  // 사업장코드
        processLevelCd: '20',  // 코드(공사구분:10, 공종 : 20)
        planStartDt: '',  // 시작예정일
        planEndDt: '',  // 종료예정일
        realStartDt: '',  // 실제시작일
        realEndDt: '',  // 실제종료일
        resultPercent: '',  // 실적진행율
        vendors: '', // 공사업체
        remark: '',  // 비고
        sortOrder: '',  // 정렬순서
      })
    },
    saveMat() {
      if (this.$comm.validTable(this.grid.columns, this.grid.data)) {
        window.getApp.$emit('CONFIRM', {
          title: '확인',
          message: '저장하시겠습니까?',
          type: 'info',
          // 확인 callback 함수
          confirmCallback: () => {
            this.$http.url = this.save1Url;
            this.$http.type = 'POST';
            this.$http.param = this.grid.data;
            this.$http.request((_result) => {
              this.getList();
              this.$emit('updatePeojectInfo', _result.data);
              window.getApp.$emit('APP_REQUEST_SUCCESS');
            });
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      }
    },
    deleteMat() {
      let selectData = this.$refs['grid'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: '안내', // 안내
          message: '하나 이상 선택하세요.', // 하나 이상 선택하세요.
          type: 'warning', // success / info / warning / error
        });
      } else {
        window.getApp.$emit('CONFIRM', {
          title: '확인',
          message: '삭제하시겠습니까?',
          type: 'warning', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            this.$http.url = this.$format(this.delete1Url);
            this.$http.type = 'DELETE';
            this.$http.param = {
              data: Object.values(selectData)
            };
            this.$http.request(() => {
              this.$emit('updatePeojectInfo');
              window.getApp.$emit('APP_REQUEST_SUCCESS');
              this.$_.forEach(selectData, item => {
                this.grid.data = this.$_.reject(this.grid.data, item);
              })
              this.$refs['grid'].$refs['compo-table'].clearSelection();
            },);
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      }
    },
    
    addrow2() {
      this.popupOptions.title = '도급업체 검색'; // 도급업체 검색
      this.popupOptions.param = {
        type: 'multiple',
        noplantCd: true,
      };
      this.popupOptions.target = () => import(`${'@/pages/common/vendor/vendorPop.vue'}`);
      this.popupOptions.width = '50%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closePopup;
    },
    closePopup(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (data && data.length > 0) {
        this.$_.forEach(data, item => {
          if(this.$_.findIndex(this.grid2.data, { vendorCd: item.vendorCd }) === -1) {
            this.grid2.data.push({
              editFlag: 'C',
              plantCd: this.param.plantCd,  // 공사현장코드
              pjmProjectVendorId: uid(),  // 하도급 일련번호
              vendorCd: item.vendorCd,
              vendorName: item.vendorName,
              processCds: '',  // 관련공종코드_멀티
              contractDt: '',  // 계약일
              contractPrice: '',  // 계약금액
              constructionStartDt: '',  // 공사시작일
              constructionEndDt: '',  // 공사종료일
              vendorRoleUser01: '',  // 현장책임자
              vendorRoleUser01Name: '',  // 현장책임자
              vendorRoleUser02: '',  // 안전관리책임자
              vendorRoleUser02Name: '',  // 안전관리책임자
              vendorRoleUser03: '',  // 안전관리자
              vendorRoleUser03Name: '',  // 안전관리자
              vendorRoleUser04: '',  // 안전관리보조원
              vendorRoleUser04Name: '',  // 안전관리보조원
            })
          }
        });
      }
    },
    saveMat2() {
      if (this.$comm.validTable(this.grid2.columns, this.grid2.data)) {
        window.getApp.$emit('CONFIRM', {
          title: '확인',
          message: '저장하시겠습니까?',
          type: 'info',
          // 확인 callback 함수
          confirmCallback: () => {
            this.$http.url = this.save2Url;
            this.$http.type = 'POST';
            this.$http.param = this.grid2.data;
            this.$http.request(() => {
              this.getList2();
              this.$emit('updateProjectInfo');
              window.getApp.$emit('APP_REQUEST_SUCCESS');
            });
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      }
    },
    deleteMat2() {
      let selectData = this.$refs['grid2'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: '안내', // 안내
          message: '하나 이상 선택하세요.', // 하나 이상 선택하세요.
          type: 'warning', // success / info / warning / error
        });
      } else {
        window.getApp.$emit('CONFIRM', {
          title: '확인',
          message: '삭제하시겠습니까?',
          type: 'warning', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            this.$http.url = this.$format(this.delete2Url);
            this.$http.type = 'DELETE';
            this.$http.param = {
              data: Object.values(selectData)
            };
            this.$http.request(() => {
              // this.getList2();
              this.$emit('updateProjectInfo');
              window.getApp.$emit('APP_REQUEST_SUCCESS');
              this.$_.forEach(selectData, item => {
                this.grid2.data = this.$_.reject(this.grid2.data, item);
              })
              this.$refs['grid2'].$refs['compo-table'].clearSelection();
            },);
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      }
    },
    addOtherProcess() {
      this.popupOptions.title = '타 공사현장 공종';
      this.popupOptions.param = {plantCd: this.param.plantCd};
      this.popupOptions.target = () => import(`${"@/pages/common/process/projectOtherProcessPop.vue"}`);
      this.popupOptions.visible = true;
      this.popupOptions.isFull = false;
      this.popupOptions.width = '50%';
      this.popupOptions.closeCallback = this.closeOtherPopup;
    },
    closeOtherPopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      this.getList();
      this.$emit('updatePeojectInfo');
    },
    datachange(row) {
      if (row.editFlag !== 'C') {
        row.editFlag = 'U'
        row.chgUserId = this.$store.getters.user.userId
      }
    },
  }
};
</script>